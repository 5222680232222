// YouTubeEmbed.js

import React from 'react';

const YouTubeEmbed = () => {
  return (
  
      <iframe

        src="https://www.youtube.com/embed/b3n3VdiM138?si=mHxdY2v9_sHZaQVS"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
        style={{ position: 'absolute', top: 250, left: 100, width: '60%', height: '60%',  }}
      ></iframe>

  );
};

export default YouTubeEmbed;
