import email from "../assets/email.png";

import api from "../assets/api.png";
import mobile from "../assets/mobile.png";

import css from "../assets/css.png";
import figma from "../assets/figma.png";

import git from "../assets/git.png";

import html from "../assets/html.png";
import javascript from "../assets/javascript.png";

import python from "../assets/python.png";
import react from "../assets/react.png";

import sass from "../assets/sass.png";
import typescript from "../assets/typescript.png";

import about01 from "../assets/about01.png";
import about02 from "../assets/about02.png";
import about03 from "../assets/about03.png";
import about04 from "../assets/about04.png";

import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";


import three from "../assets/three.png";
import blender from "../assets/blender.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  email,

  api,

  css,
  figma,
  git,

  html,
  javascript,

  python,
  react,

  sass,
  typescript,

  about01,
  about02,
  about03,
  about04,
  profile,
  circle,

  mobile,



  three,
  blender,
};
