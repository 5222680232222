import React from "react";
// import React, { useState, useEffect } from "react";

import { About, Footer, Header, Projects, Skills } from "./container";
import { Navbar } from "./components";
import "./App.scss";

const App = () => {
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   function handleResize() {
  //     setIsMobile(window.innerWidth < 768);
  //   }
  //   window.addEventListener("resize", handleResize);
  //   handleResize();
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    // <div>
    //   {isMobile ? (
    //     <div className="message">
    //       <h1>Welcome to ArtechFuz3D</h1>
    //       <p>To view this website, please use a desktop or laptop computer.</p>
    //     </div>
    //   ) : (
    //     <div className="app">
    //       <Navbar />
    //       <Header />
    //       <About />
    //       <Projects />

    //       <Skills />

    //       <Footer />
    //     </div>
    //   )}
    // </div>

 
      <div className="app">
        <Navbar />
        <Header />
        <About />
        <Projects />

        <Skills />

        <Footer />
      </div>


  );
};

export default App;
