import React from "react";

import { FaInstagram, FaGithubAlt, FaLinkedin } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://linktr.ee/artechfuz3d"
          rel="noreferrer"
          target="_blank"
        >
          <FaInstagram />
        </a>
      </div>
      <div>
        <a
          href="https://www.linkedin.com/in/neill-hewitt-artechfuz3d/"
          rel="noreferrer"
          target="_blank"
        >
          <FaLinkedin />
        </a>
      </div>
      <div>
        <a
          href="https://github.com/ArtechFuz3D-Studio"
          rel="noreferrer"
          target="_blank"
        >
          <FaGithubAlt />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
